import { arrayOf, Schema } from 'normalizr';
import { getAvailabilityKey } from '../orm/availability/availability.helper';
import { getDaylogKey } from '../orm/daylog/daylog.helper';

export const NewsItemSchema = new Schema('newsItem');
export const CommentSchema = new Schema('comment');
export const EmployeeSchema = new Schema('employees');
export const LocationSchema = new Schema('locations');
export const DepartmentSchema = new Schema('departments', { meta: { removeProps: ['NewsItemsDepartment'] } });
export const TeamSchema = new Schema('teams', { meta: { removeProps: ['UsersTeam'] } });
export const AvailabilitySchema = new Schema('availabilities', { idAttribute: getAvailabilityKey });
export const EventSchema = new Schema('events');
export const ExchangeSchema = new Schema('exchanges');
export const OpenShiftSchema = new Schema('openShifts', { idAttribute: 'occurrence_id' });
export const ReportSchema = new Schema('reports');
export const RequiredShiftSchema = new Schema('requiredShifts', { idAttribute: 'occurrence_id' });
export const ShiftSchema = new Schema('shifts');
export const TimesheetSchema = new Schema('timesheets');
export const ScheduleSchema = new Schema('schedules', { idAttribute: 'occurrence_id' });
export const CorrectionSchema = new Schema('corrections');
export const ContractTypeSchema = new Schema('contractTypes');
export const ContractSchema = new Schema('contracts');
export const RateCardSchema = new Schema('rateCards');
export const HolidaySchema = new Schema('holiday');
export const SurchargeSchema = new Schema('surcharge');
export const ClockLocationSchema = new Schema('clockLocations');
export const ClockIpAddressSchema = new Schema('clockIpAddress');
export const BreakRuleSchema = new Schema('breakRule');
export const AbsenteeOptionSchema = new Schema('absenteeOption');
export const AbsenceSchema = new Schema('absence');
export const DaylogSchema = new Schema('daylog', { idAttribute: getDaylogKey });
export const TeamDaySchema = new Schema('teamDays');
export const UserFileSchema = new Schema('userFiles');
export const UserNoteSchema = new Schema('userNotes');
export const AccountFileSchema = new Schema('accountFiles');
export const ModuleSchema = new Schema('modules');
export const PermissionSchema = new Schema('permissions');
export const PermissionGroupSchema = new Schema('permissionGroups', {
  meta: { removeProps: ['AbsenteeOptionsGroup'] },
});
export const NotificationSchema = new Schema('notification');
export const ApiUserSchema = new Schema('apiUser');
export const IntegrationSchema = new Schema('integration');
export const AppTokenSchema = new Schema('appToken');
export const SkillSchema = new Schema('skill');
export const SkillGroupSchema = new Schema('skillGroup');
export const WeatherSchema = new Schema('weather');

EmployeeSchema.define({
  Team: arrayOf(TeamSchema),
});

DepartmentSchema.define({
  Team: arrayOf(TeamSchema),
  Shift: arrayOf(ShiftSchema),
  Location: LocationSchema,
});

LocationSchema.define({
  Department: arrayOf(DepartmentSchema),
});

ContractTypeSchema.define({
  RateCard: arrayOf(RateCardSchema),
});

ScheduleSchema.define({
  Team: TeamSchema,
  Shift: ShiftSchema,
  User: EmployeeSchema,
  Exchange: ExchangeSchema,
});

RateCardSchema.define({
  RateCard: arrayOf(SurchargeSchema),
});

TimesheetSchema.define({
  Roster: ScheduleSchema,
  Team: TeamSchema,
  Shift: ShiftSchema,
  User: EmployeeSchema,
  Location: LocationSchema,
  Department: DepartmentSchema,
});

NewsItemSchema.define({
  Department: arrayOf(DepartmentSchema),
});

ExchangeSchema.define({
  Roster: ScheduleSchema,
});

AbsenteeOptionSchema.define({
  Group: arrayOf(PermissionGroupSchema),
});

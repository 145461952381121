import { DaylogModel, DaylogRequiredData } from './daylog.model';

export const defaultDayLog = (date: string, departmentId: string): DaylogModel => ({
    date: date,
    department_id: departmentId,
    finished_timesheet: false,
    published_schedule: false,
    expected_turnover: '0',
    tip: '',
    log: '',
    isPublished: false,
    canPublish: false,
    saving: false,
  });

export const getDaylogKey = (daylog: DaylogRequiredData) => `${daylog.department_id}|${daylog.date}`;

import { Dictionary } from '@ngrx/entity';
import get from 'lodash-es/get';
import includes from 'lodash-es/includes';
import isArray from 'lodash-es/isArray';
import isEmpty from 'lodash-es/isEmpty';
import omit from 'lodash-es/omit';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { format, parseDate } from '../../shared/date.helper';
import { AppState } from '../index';
import { UnsafeAction as Action } from '../interfaces';
import { DepartmentModel } from '../orm/department/department.model';
import { ObjectMap } from '../orm/index';
import { TeamModel } from '../orm/team/team.model';

export enum PayloadKeyType {
  COMMENT = 'comments',
  ABSENCE = 'absence',
  LOCATIONS = 'locations',
  EMPLOYEES = 'employees',
  AVAILABILITIES = 'availabilities',
  NEWSITEMS = 'newsItem',
  EVENTS = 'events',
  OPENSHIFTS = 'openShifts',
  REQUIRED_SHIFTS = 'requiredShifts',
  RATECARDS = 'rateCards',
  SCHEDULES = 'schedules',
  EXCHANGES = 'exchanges',
  DEPARTMENTS = 'departments',
  SHIFTS = 'shifts',
  TEAMS = 'teams',
  TIMESHEETS = 'timesheets',
  SURCHARGES = 'surcharges',
  ABSENTEE_OPTIONS = 'absenteeOptions',
  WEATHER = 'weather',
  REPORTS = 'reports',
  TIME_OFF_BALANCES = 'timeOffBalances',
  TEAM_DAYS = 'teamDays',
  LOGS = 'daylog',
}

export const getAppState = (appState: AppState) => appState;

/**
 * This function returns an array from the action payload for the specified key type
 * @param key
 * @param payload
 */
export function getArrayFromActionPayload(key: PayloadKeyType, payload) {
  let data = [];
  if (!payload) {
    return data;
  }
  if (payload[key]) {
    data = Object.values(payload[key]);
  }
  return data;
}

export function containsEntity(action: Action, entity: string) {
  return action.payload && action.payload.entities && action.payload.entities[entity];
}

export function getEntities(action: Action, entity: string) {
  return get(action, ['payload', 'entities', entity], void 0);
}

export function getResultEntity(action: Action, entity: string) {
  const entities = getEntities(action, entity);

  return entities[action.payload.result];
}

export function reformatListResponse(key, data) {
  return data.map((row) => reformatEntityResponse(key, row));
}

export function reformatEntityResponse(key, row) {
  if (!row[key]) {
    return {};
  }
  const reformatted = row[key];
  const relatedRecords = omit(row, key);

  return Object.assign(reformatted, relatedRecords);
}

export function mapEntities<T>(ids: any[], itemsById: ObjectMap<T>): T[] {
  return ids.map((entityId) => itemsById[entityId]).filter((entity) => entity !== void 0);
}

export function mapAndSortEntities(sortFn: Function) {
  return (ids: any[], itemsById) => sortFn(mapEntities(ids, itemsById));
}

export function mapEntity(id: string, itemsById) {
  if (!itemsById[id]) {
    return;
  }

  return itemsById[id];
}

export function getMappedEntities(ids: string[]) {
  return (obs: Observable<any>) =>
    obs.pipe(
      map((state) => {
        if (isEmpty(state.itemsById)) {
          return [];
        }

        return mapEntities(ids, state.itemsById);
      }),
    );
}

export function filterEntities(ids: string[], itemsById: any[]) {
  return itemsById.filter((item) => includes(ids, item.id));
}

export function filterDeleted(entities: any[], mustHaveId?) {
  return entities.filter((entity) => {
    if (mustHaveId && entity.id === mustHaveId) {
      return true;
    }

    return !entity.deleted;
  });
}

type FilterFunc = (value: any) => boolean;

export function combinedFilter(entities: any[], ...filters: FilterFunc[]) {
  return entities.filter((entity) => filters.every((filter) => filter(entity)));
}

export const convertArrayToObject = (idKey: string, arrayToConvert: any[]): Dictionary<any> => {
  if (!isArray(arrayToConvert)) {
    return arrayToConvert;
  }
  const dict = {};
  if (!isArray(arrayToConvert)) {
    return dict;
  }
  for (const entity of arrayToConvert) {
    if (!entity[idKey]) {
      continue;
    }
    dict[entity[idKey]] = entity;
  }
  return dict;
};

export const isEntityActiveForDate = (date: string) => (entityToCheck: DepartmentModel | TeamModel) => {
  if (!entityToCheck) {
    return false;
  }

  const deletedDate = format(parseDate(entityToCheck.deleted_date), 'yyyy-MM-dd');

  return !entityToCheck.deleted || (entityToCheck.deleted && deletedDate >= date);
};
